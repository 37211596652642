<template>
  <div>
    <b-card
      class="border-0"
      body-class="mb-3 bg-gray-200 p-2 hide-on-empty"
    >
      <invoice-send-mail
        :entity-id="requestName"
        :entity-class="entityClass"
        :context="userInvoice.status"
        environment="INVOICE"
        language="it"
      />
    </b-card>
    <div class="px-3">
      <b-row>
        <b-col md="6" lg="4">
          <p>
            <strong>Data Creazione</strong>
            <br />
            {{userCreatedAt}}
          </p>
        </b-col>
        <b-col md="6" lg="4">
          <p>
            <strong>Data Modifica</strong>
            <br />
            {{userLastModified}}
          </p>
        </b-col>
        <b-col md="6" lg="4">
          <p>
            <strong>Status</strong>
            <br />
            <badge
              :status="userInvoice.status"
              statuses="invoice"
            />
          </p>
        </b-col>
      </b-row>
      <div class="mt-3">
        <b-row>
          <b-col md="6" xl="9" class="d-flex flex-column">
            <label class="col-form-label col-form-label--accent">destinatario</label>
            <div class="h-100 d-flex flex-column overflow-hidden">
              <p>
                <strong>{{userInvoice.invoicedTo.companyName}}</strong>
                <br>
                <span v-if="userInvoice.invoicedToDetail.legalAddress">
                  <span>
                    {{userInvoice.invoicedToDetail.legalAddress.streetName}}
                  </span>
                  <span v-if="userInvoice.invoicedToDetail.legalAddress.streetNumber">, </span>
                  <span>
                    {{userInvoice.invoicedToDetail.legalAddress.streetNumber}}
                  </span>
                  <br>
                  <span>
                    {{userInvoice.invoicedToDetail.legalAddress.zip}}
                  </span>
                  <span>
                    {{userInvoice.invoicedToDetail.legalAddress.city}}
                  </span>
                  <span
                    v-if="hasZipCity"
                  >
                    -
                  </span>
                  <span>
                    {{userInvoice.invoicedToDetail.legalAddress.province}}
                  </span>
                  <span>
                    ({{userInvoice.invoicedToDetail.legalAddress.countryCode}})
                  </span>
                </span>
              </p>
              <p>
                <span v-if="userInvoice.invoicedTo.vatNumber">
                  <strong>P.IVA:</strong>
                  {{userInvoice.invoicedTo.vatNumber}}
                </span>
                <br v-if="userInvoice.invoicedTo.vatNumber">
                <span v-if="userInvoice.invoicedToDetail.info.taxCode">
                  <strong>C.F.:</strong>
                  {{userInvoice.invoicedToDetail.info.taxCode}}
                </span>
              </p>
            </div>
          </b-col>
          <b-col md="6" xl="3" class="d-flex flex-column">
            <label class="col-form-label col-form-label--accent">Rating</label>
            <div class="h-100 d-flex flex-column overflow-hidden">
              <div class="text-center my-auto pb-2">
                <h6 class="mb-0 rating-label">
                  {{ invoiceRatingValue }}
                </h6>
              </div>
              <b-row
                v-if="checkRating"
                class="bg-gray-200 p-2 mt-auto d-flex align-items-center"
              >
                <b-col class="me-3">
                  <p class="font-size--xs mb-0">
                    <strong>{{ invoiceRatingDateLabel }}:</strong>
                    <br>
                    {{ invoiceRatingDateValue }}
                  </p>
                </b-col>
                <b-col class="ms-auto mt-2 d-flex justify-content-xl-end">
                  <b-btn
                    variant="success"
                    size="xs"
                    class="white-space-nowrap"
                    @click="updateScoring"
                  >
                    <font-awesome-icon icon="refresh" class="me-1"></font-awesome-icon>
                    <span>Aggiorna rating</span>
                  </b-btn>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mt-3">
        <label class="col-form-label col-form-label--accent">dettaglio fattura</label>
        <div>
          <b-row>
            <b-col md="6">
              <p>
                <strong>N° Fattura</strong>
                <br />
                {{userInvoice.invoiceInfo.invoiceNumber}}
              </p>
            </b-col>
            <b-col md="6">
              <p>
                <strong>Valore nominale</strong>
                <br />
                {{invoiceValue}}
              </p>
            </b-col>
            <b-col md="6">
              <p>
                <strong>Data di emissione</strong>
                <br />
                {{invoiceEmissionDate}}
              </p>
            </b-col>
            <b-col md="6">
              <p>
                <strong>Data di scadenza</strong>
                <br />
                {{invoiceExpireDate}}
              </p>
            </b-col>
            <b-col md="6">
              <p>
                <strong>Giorni alla scadenza</strong>
                <br />
                {{dayToExpireComputed}}gg
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="mt-3">
        <label class="col-form-label col-form-label--accent">situazione finanziaria</label>
        <div>
          <b-row>
            <b-col md="6" lg="4">
              <p>
                <font-awesome-icon :icon="iconInfo" class="fa-fw" />
                <strong>
                  Inviato notifica
                </strong>
                <br>
                {{sendInfoMailComp}}
              </p>
            </b-col>
            <b-col md="6" lg="4">
              <p>
                <font-awesome-icon :icon="iconRemainder" class="fa-fw" />
                <strong>
                  Sollecitata
                </strong>
                <br>
                {{sendRemainderMailComp}}
              </p>
            </b-col>
            <b-col md="6" lg="4">
              <p>
                <font-awesome-icon :icon="iconFactoring" class="fa-fw" />
                <strong>
                  Factoring/cessione
                </strong>
                <br>
                {{factoringComp}}
              </p>
            </b-col>
            <b-col md="6" lg="4">
              <p>
                <font-awesome-icon :icon="iconAdvance" class="fa-fw" />
                <strong>
                  Anticipo fattura
                </strong>
                <br>
                {{advanceInvoiceComp}}
              </p>
            </b-col>
            <b-col md="6" lg="4">
              <p>
                <font-awesome-icon :icon="iconClose" class="fa-fw" />
                <strong>
                  Incassata
                </strong>
                <br>
                {{closeStatusComp}}
              </p>
            </b-col>
            <b-col md="6" lg="4">
              <p>
                <font-awesome-icon :icon="iconAborted" class="fa-fw" />
                <strong>
                  Nota di credito
                </strong>
                <br>
                {{abortedStatusComp}}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="mt-3 px-3 pt-3 border-top">
      <document-list
        :entity-id="requestName"
        :entity-class="entityClass"
        :documents="documents"
        slim
        genericListTitle="Documenti"
        isGenericList
      />
    </div>
    <div class="mt-3 px-3 pt-3 border-top" v-if="hasNotes">
      <b-card>
        <template #header>
          <card-header
            title="Note"
          />
        </template>
        <div>
          <user-invoice-note
            v-for="(note, index) in invoiceNotes"
            :key="`invoiceNote_${index}`"
            :index="index"
            :note="note"
            statuses="invoice"
          />
        </div>
      </b-card>
    </div>
    <b-card
      class="border-0"
      body-class="mt-3 bg-gray-200 p-2 hide-on-empty justify-content-end d-flex"
    >
      <b-button
        variant="danger"
        size="sm"
        @click="deleteInvoiceTrigger"
        v-if="isDraft"
      >
        Elimina
      </b-button>
      <workflow-buttons
        :workflow="invoiceStatusWorkflow"
        :buttonsInfo="buttonsInfo"
        :changeAction="doStatusChange"
      />
    </b-card>
  </div>
</template>

<script>
import { isNotExist, extractErrorMessage, isNotEmpty } from '@/utils/validators';
import { formatDateTime, formatDateOnly, formatCurrency } from '@/utils/formatterHelper';
import { toastFailure, toastSuccess } from '../../utils/toast';

const Badge = () => import('@/components/helpers/Badge.vue');
const DocumentList = () => import('@/components/documents/DocumentList.vue');
const WorkflowButtons = () => import('@/components/workflowButtons/WorkflowButtons.vue');
const UserInvoiceNote = () => import('./UserInvoiceNote.vue');
const InvoiceSendMail = () => import('./InvoiceSendMail.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'UserInvoiceDetailInfo',
  components: {
    DocumentList,
    WorkflowButtons,
    CardHeader,
    UserInvoiceNote,
    InvoiceSendMail,
    Badge,
  },
  props: {
    userInvoice: Object,
    requestName: String,
  },
  data() {
    return {
      entityClass: 'INVOICE',
    };
  },
  computed: {
    documents() {
      // console.log('userInvoice', this.userInvoice);
      // console.log('doc check', this.$store.getters['documents/getDocumentList'](this.entityClass, this.requestName));
      return this.$store.getters['documents/getDocumentList'](this.entityClass, this.requestName);
    },
    isDraft() {
      return this.userInvoice.status === 'DRAFT';
    },
    statusVariant() {
      const { status } = this.userInvoice;
      const a = this.buttonsInfo[status];
      if (a?.theme) {
        return a.theme;
      }
      return 'primary';
    },
    statusLabel() {
      const { status } = this.userInvoice;
      const a = this.buttonsInfo[status];
      if (a?.label) {
        return a.label;
      }
      return `label ${a} mancante`;
    },
    userCreatedAt() {
      return formatDateTime(this.userInvoice.createdAt);
    },
    userLastModified() {
      return formatDateTime(this.userInvoice.lastModified);
    },
    // isProcessed() {
    //   const { status } = this.userInvoice;
    //   return status === 'COMPLETED';
    // },
    headingText() {
      return `Dettaglio Richiesta: ${this.userInvoice.invoiceInfo.invoiceNumber}`;
    },
    invoiceEmissionDate() {
      return formatDateOnly(this.userInvoice.invoiceInfo.invoiceDate);
    },
    invoiceExpireDate() {
      return formatDateOnly(this.userInvoice.invoiceInfo.invoiceExpireDate);
    },
    hasZipCity() {
      const a = this.userInvoice.invoicedToDetail.legalAddress.zip;
      const b = this.userInvoice.invoicedToDetail.legalAddress.city;
      return a || b;
    },
    invoiceStatusWorkflow() {
      // console.log('invoice workflow', this.$store.getters['invoice/getInvoiceNextStatusUpdatable']);
      return this.$store.getters['invoice/getInvoiceNextStatusUpdatable'];
    },
    buttonsInfo() {
      return this.$store.getters['tableUtils/getStatuses']('invoice');
    },
    invoiceNotes() {
      return this.userInvoice.changeStatusNotes || [];
    },
    hasNotes() {
      return this.userInvoice.changeStatusNotes?.length > 0;
    },
    dayToExpireComputed() {
      return this.userInvoice.calculationInfo?.dayToExpire;
    },
    iconInfo() {
      return 'paper-plane';
    },
    iconRemainder() {
      return 'hourglass';
    },
    iconFactoring() {
      return 'file-signature';
    },
    iconAdvance() {
      return 'building-columns';
    },
    iconClose() {
      return 'vault';
    },
    iconAborted() {
      return 'comments-dollar';
    },
    notAvailableMsg() {
      return 'ND';
    },
    sendInfoMailComp() {
      const a = this.userInvoice.additionalInfos?.sendInfoMail;
      const b = formatDateTime(this.userInvoice.additionalInfos?.sendInfoMail);
      return a ? b : this.notAvailableMsg;
    },
    sendRemainderMailComp() {
      const a = this.userInvoice.additionalInfos?.sendRemainderMail;
      const b = formatDateTime(this.userInvoice.additionalInfos?.sendRemainderMail);
      return a ? b : this.notAvailableMsg;
    },
    factoringComp() {
      const a = isNotEmpty(this.userInvoice.additionalInfos?.invoiceSendFactor);
      const b = isNotEmpty(this.userInvoice.additionalInfos?.invoicePurchased);
      if (a) {
        return formatDateTime(this.userInvoice.additionalInfos?.invoiceSendFactor);
      }
      if (b) {
        return formatDateTime(this.userInvoice.additionalInfos?.invoicePurchased);
      }
      return this.notAvailableMsg;
    },
    advanceInvoiceComp() {
      const a = this.userInvoice.additionalInfos?.invoiceSendBank;
      const b = formatDateTime(this.userInvoice.additionalInfos?.invoiceSendBank);
      return a ? b : this.notAvailableMsg;
    },
    closeStatusComp() {
      if (this.userInvoice.status === 'CLOSE') {
        return formatDateTime(this.userInvoice.lastModified);
      }
      return this.notAvailableMsg;
    },
    abortedStatusComp() {
      if (this.userInvoice.status === 'ABORTED') {
        return formatDateTime(this.userInvoice.lastModified);
      }
      return this.notAvailableMsg;
    },
    checkRating() {
      return isNotEmpty(this.userInvoice?.invoicedTo?.rating);
    },
    invoiceRatingValue() {
      if (this.checkRating) {
        return this.userInvoice.invoicedTo.rating;
      }
      return this.notAvailableMsg;
    },
    invoiceRatingDateLabel() {
      return 'Ultimo aggiornamento';
    },
    invoiceRatingDateValue() {
      if (this.checkRating) {
        return formatDateTime(this.userInvoice.invoicedTo.ratingDate);
      }
      return this.notAvailableMsg;
    },
    invoiceValue() {
      if (isNotEmpty(this.userInvoice?.invoiceInfo?.invoiceGross?.amount)) {
        return formatCurrency(this.userInvoice.invoiceInfo.invoiceGross.amount, 'it', 'EUR');
      }
      return this.notAvailable;
    },
  },
  mounted() {
    this.loadDocuments();
  },
  methods: {
    loadDocuments() {
      console.debug('Load documents', this.userInvoice);
      if (isNotExist(this.userInvoice)) return;
      // if (this.isProcessed) {
      this.$store.dispatch('documents/loadDocuments', {
        entityClass: this.entityClass,
        entityId: this.requestName,
      });
      // }
    },
    backToList() {
      this.$router.back();
    },
    deleteInvoiceTrigger() {
      // console.log('eliminazione > pop-up conferma');
      const a = this.userInvoice.invoiceInfo.invoiceNumber;
      const deleteMessage = `Vuoi davvero eliminare la fattura ${a}`;
      this.$confirm(
        {
          message: deleteMessage,
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              this.deleteInvoiceAction();
            }
          },
        },
      );
    },
    deleteInvoiceAction() {
      this.$store.dispatch('invoice/deleteInvoice', this.requestName).then((response) => {
        console.log('deleting', response);
        toastSuccess('Fattura eliminata con successo');
        this.$router.replace('/cr-invoice');
      },
      (error) => {
        console.log('error delete', error);
        toastFailure('Impossibile completare l\'operazione');
      });
    },
    doStatusChange(status, noteStatus) {
      const request = {
        invoiceId: this.requestName,
        status,
        noteStatus,
      };
      this.$store.dispatch('invoice/updateStatusInvoice', request).then((response) => {
        console.log('changed status', response);
        this.$store.dispatch('invoice/getInvoice', this.requestName);
        toastSuccess('Stato modificato con successo');
      },
      (error) => {
        this.message = extractErrorMessage(error);
        this.submitted = false;
        toastFailure('Impossibile completare l\'operazione');
      });
    },
    updateScoring() {
      console.log('updating rating', this.userInvoice.invoiceId);
      this.$store.dispatch('invoice/updateScoringCrpInvoice', this.userInvoice.invoiceId);
    },
  },
};
</script>

<style scoped>

</style>
